import React from 'react'
import { connectHits } from 'react-instantsearch-dom'
import { default as BlogLatestArticleGalleryUI } from '@interflora/ui-components/build/components/BlogLatestArticleGallery/BlogLatestArticleGallery'
import { HitsProvided, BasicDoc } from 'react-instantsearch-core'
import { LinkProps } from '@interflora/ui-components/build/common/props'
import { algoliaBlogArticleMapping } from '@interflora/ui-components/build/utils/common'

type Props = HitsProvided<BasicDoc> & {
  hits?: any
  title: string
  link: LinkProps
}

const ListingWrapper = ({ hits, title, link }: Props) => {
  const latestArticlesList = algoliaBlogArticleMapping(hits)
  return <BlogLatestArticleGalleryUI title={title} link={link} latestArticlesList={latestArticlesList} />
}

export default connectHits<Props, BasicDoc>(ListingWrapper)
